import React, { useState, useEffect } from 'react'
import Quiz from '../components/Quiz2'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

function Home() {
  const [quizData, setQuizes] = useState(null);
  useEffect(()=>{
    fetch('https://ya9vdqd0ah.execute-api.ap-south-1.amazonaws.com/PROD/quiz', {
      method: 'GET',
      headers: {
        'x-api-key': 'GvlxgKx38Q57rPPvPDAai5fBYQd1eNdV2vL5LpI5',
      },
    })
      .then(res => res.json())
      .then(result => {
        setQuizes(result);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      })
  },[])
  
  return (
    <main>
        <Container sx={{ py: 4 }} maxWidth="md">
          <Grid container spacing={4} xs={12}>
            {quizData && 
        quizData.map((item) => (
            <Quiz quiz={item}/>
            ))}
          </Grid>
        </Container>
      </main>
  )
}

export default Home