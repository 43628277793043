import React from 'react'
import './Quiz.css'
import { styled } from '@mui/material/styles';
import {Card, CardMedia, CardContent, Rating, Grid} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import IconButton from '@mui/material/IconButton';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import {Typography, CardActionArea} from '@mui/material';
import { red } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';


const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ff6d75',
  },
  '& .MuiRating-iconHover': {
    color: '#ff3d47',
  },
});

export default function Quiz2({quiz}) {

  const navigate = useNavigate()

  const gotoQuiz = () => {
    navigate('/questions',{state:{quiz_id:quiz.id}});
  }

  return (
    <Grid item key={quiz.id} xs={12} md={3} style={{display: 'flex'}}>
       <Card sx={{ maxWidth: 345 }} elevation={10}>
      <CardActionArea onClick={gotoQuiz}> 
        <CardMedia
          component="img"
          height="140"
          image={quiz.url}
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h7" component="div">
            {quiz.title}
          </Typography>
          <StyledRating
        name="customized-color"
        size="small"
        defaultValue={quiz.rating}
        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
        precision={0.5}
        icon={<FavoriteIcon fontSize="inherit" />}
        emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
      />
        </CardContent>
      </CardActionArea>
    </Card>
    </Grid> 
  )
}
