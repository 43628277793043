import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import { CardActionArea, Card, Grid, Container, Box, Button, Paper,CardMedia, CardContent, Typography} from '@mui/material';


function ResultPage() {
    const {state} = useLocation();
    const { correctQuestions, totalQuestions } = state; // Read values passed on state
  return (
        <Card elevation={2} align="center">
            <CardContent>
            <Typography gutterBottom variant="h2" component="div">
            Thank You !!!
              </Typography>
              <Typography gutterBottom variant="h2" component="div">
              You have got {correctQuestions} right out of {totalQuestions}
              </Typography>
              <Link to="/" className="btn btn-primary">Take Another Quiz</Link>
            </CardContent>
          </Card>
    
  )
}

export default ResultPage