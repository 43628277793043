import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { CardActionArea, Card, Grid, Container, Box, Button, Paper,CardMedia, CardContent, Typography} from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const theme = createTheme({ palette: { mode: 'light' } });
const Item = styled(Card)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: '60px',
}));

function QuestionPage2() {
  const location = useLocation()
  const { quiz_id } = location.state
  const [questionIndex, setQuestionIndex] = useState(0)
  const [totalQuestions, setTotalQuestions] = useState(0)
  const [correctQuestions, setCorrectQuestions] = useState(0)
  const [questions, setQuestions] = useState(null)
  const [show, setShow] = useState(false)
  const [correctAnswer, setCorrectAnswer] = useState(false)
  let navigate = useNavigate(); 
//  const styleButton = {backgroundColor: '#FFC100', borderColor: '#ff0000', color: '#000000', borderRadius: '1px', fontSize: '2rem'}

 
  const handleAnswer = (e) => {
    if(e.target.value == questions[questionIndex].answer)
    {
      setCorrectAnswer(true)
      setCorrectQuestions(correctQuestions + 1)
    }
    setShow(true)
  }

  const handleNext = () => {
    setShow(false)
    setCorrectAnswer(false)
    if(questionIndex + 1 < totalQuestions)
      setQuestionIndex(questionIndex + 1)
    else if (questionIndex + 1 === totalQuestions)
      navigate('/result', { state: { correctQuestions: correctQuestions, totalQuestions: totalQuestions } })
  }

  useEffect(() => {
    fetch(`https://ya9vdqd0ah.execute-api.ap-south-1.amazonaws.com/PROD/question/${quiz_id}`, {
      method: 'GET',
      headers: {
        'x-api-key': 'GvlxgKx38Q57rPPvPDAai5fBYQd1eNdV2vL5LpI5',
      },
    })
      .then(res => res.json())
      .then(result => {
        console.log(result.length);
        setQuestions(result);
        setTotalQuestions(result.length);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);
  return (
    <Container sx={{ py: 4 }}>
      <Grid container spacing={1} align="center">
        <Grid item xs={12}>
         <Card elevation={0}>
            <CardMedia
              sx={{ height: 225, width: 400 }}
              image={questions && questions[questionIndex].url}
              title="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h4" component="div">
              {questions && questions[questionIndex].question}
              </Typography>
            </CardContent>
          </Card>
        </Grid>  
          {[... Array(4)].map((optionItem, index) =>
        <Grid item xs={6}>
                <Item key={index+1} elevation={5} style={{ fontSize: '20px'}} >
                  <CardActionArea onClick={handleAnswer} value={index+1}>
                  {questions && questions[questionIndex][`option`+(index+1)]}
                  </CardActionArea>
                </Item>
        </Grid>
          )}
    </Grid>
    {<Dialog
      open={show}
      fullWidth='true'
      TransitionComponent={Transition}
      keepMounted
      onClose={handleNext}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle textAlign="center">{correctAnswer? 'Woohoo':'Oops'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description"  textAlign="center">
          {questions && questions[questionIndex][`option`+[questions[questionIndex].answer]]}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleNext}>Next</Button>
      </DialogActions>
    </Dialog>}
    </Container>
  )
}
export default QuestionPage2