import React from 'react'

function Thankyou() {
  return (
    <div className='thankyou'>
        <h1>Thank You</h1>
    </div>
  )
}

export default Thankyou