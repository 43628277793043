import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Navbar from './components/Navbar3';
import Home from './pages/Home2';
import {  BrowserRouter as Router ,Routes, Route} from 'react-router-dom';
import Thankyou from './pages/Thankyou';
import QuestionPage from './pages/QuestionPage2';
import ResultPage from './pages/ResultPage';

const defaultTheme = createTheme();

export default function App2() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Router>
      <Navbar />
      <Routes>
        <Route path='/' exact Component={Home}/>
        <Route path='/quiz' Component={Home}/>
        <Route path='/questions' Component={QuestionPage}/>
        <Route path='/thankyou' Component={Thankyou}/>
        <Route path='/result' Component={ResultPage}/>
      </Routes>
    </Router>
      {/* Footer */}
      <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
        <Typography variant="h6" align="center" gutterBottom>
          Funzoosh
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          Friday Fun Time!
        </Typography>
        <Copyright />
      </Box>
      {/* End footer */}
    </ThemeProvider>
  );
}


function Copyright() {
    return (
      <Typography variant="body2" color="text.secondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://funzoosh.com/">
          Funzoosh
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
}
  